import { Search } from 'auto-libs'
import { Alert, Button, Layout, Loading, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import Card from '../../components/card'
import ContentCard from '../../components/content-card'
import FeedBack from '../../components/feedback'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { delHomePageCouponPop, setXNameTest } from '../../utils/storage'
import { sleep } from '../../utils/time'
import ClassifyHeader from './components/classify-header'
import AdName from './imgs/ad-name.jpg'
import AdQinggan from './imgs/ad-qinggan.png'
import AdYinyuan from './imgs/ad-yinyuan.png'
import GuaImg from './imgs/gua.jpeg'
import './style.scss'

export interface IState {
  loading: boolean
  errorMsg: string
  resultData: any
  alipayForm: string
  bName: string
  gName: string
  result: any
}

// 男孩姓名
// private String bName;
// //女孩姓名
// private String gName;
// //配对指数
// private int pdzs;
// //男生恋爱性格
// private String blaxg;
// //女生恋爱性格
// private String glaxg;
// //彼此内心想法--男孩
// private String bbcnxxf;
// //彼此内心想法--女孩
// private String gbcnxxf;
// //未来关系发展
// private String wlgxfz;
// //恋人相处指南
// private String lrxczn;
// //能见面，就多见面
// private String njmjdjm;
// //尊重两性差异
// private String zzlxcy;
// //互相付出
// private String hxfc;
// //主动自我表露
// private String zdzwbl;
// //多表达感激
// private String dbdgj;
// //神秘感
// private String smg;
// //塑造自身价值
// private String szzsjz;

const PageResult = () => {
  const orderId = Search.get('orderNo') || ''
  const backTo = Search.get('backTo') || ''
  const navigateHook = useNavigate()

  const [state, setState] = useSetState<IState>({
    loading: false,
    errorMsg: '',
    resultData: '',
    alipayForm: '',
    bName: '',
    gName: '',
    result: {},
  })

  let timer: any = null
  let payWay = 'WXPAY'
  let alertCloseHandler: any = null

  const onBackHome = () => {
    if (backTo === 'order') {
      navigateHook('/order', { replace: true })
    } else {
      navigateHook('/', { replace: true })
    }
  }

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      await sleep(1500)

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: 'result/queryHehun',
        method: 'POST',
        data: {
          orderId,
        },
      })

      delHomePageCouponPop()

      setXNameTest('done')

      setState({ result: result.body })
    } catch (e: any) {
      setState({ errorMsg: e.message || '系统异常，请重试' })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId)

    setState({ alipayForm: result })

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    const [close] = Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })

    alertCloseHandler = close
  }

  // const sleep = (time: number) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve('done')
  //     }, time)
  //   })
  // }

  useEffect(() => {
    fetchMatchResult()

    return () => {
      timer && clearTimeout(timer)

      alertCloseHandler && alertCloseHandler()
    }
  }, [])

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Body className="body" loading={state.loading} errorInfo={state.errorMsg}>
        <div className="inner">
          <Card title="姓名配对结果">
            <div className="scores">
              <div className="male man" />
              <div className="score">{state.result.pdzs}</div>
              <div className="female man" />
            </div>

            <ContentCard title="合婚分数">
              <div className="txt">
                <em>温馨提示：</em>92分及以上表明你们是天造地设的
                一对，而80分及以上表明这段关系是天赐良缘。74分及以上表明你们可以交往，但需要注意各
                自的缺点，并予以改正方能走进婚姻殿堂。
                合婚分数在74以下表明你们不太适合交往，但可以通过改正自己的性格缺点并通过‘情感建议、
                家居环境调整’调和二人的交往气场，保持长久 稳定的关系。
              </div>
            </ContentCard>
          </Card>
          <Card title="你们的姓名合盘" className="gua-wrapper">
            <div className="title" />
            <div className="gua">
              <span>火地晋</span>
              <span>中上挂</span>
            </div>
            <div className="img">
              <img src={GuaImg} />
            </div>

            <div className="names">
              <div className="b">{state.result.bname}</div>
              <div className="g">{state.result.gname}</div>
            </div>

            <div className="txt">
              <em>象曰：</em>
              锄地锄去苗里草，谁想财帛将人找，一锄锄出银子来，这个运气也算好。这个卦是异卦（下坤上离）相叠。离为日，为光明；坤为地。太阳高悬，普照大地，大地卑顺，万物生长，光明磊落，柔进上行，喻事业蒸蒸日上。
            </div>
            <div className="txt">
              <em>卦语：</em>相思似海深，旧事如天远。
            </div>
            <div className="txt">
              <em> 注解：</em>
              吉星高照。会有理想的结果，但决不可自恃条件优越而抱无所谓的态度或过于挑剔。
            </div>
          </Card>
          <Card title="你们的恋爱性格">
            <ContentCard title="男生恋爱性格">
              <div className="txt">{state.result.blaxg}</div>
            </ContentCard>
            <ContentCard title="女生恋爱性格">
              <div className="txt">{state.result.glaxg}</div>
            </ContentCard>
          </Card>
          <Card title="你们的关系发展">
            <ContentCard title="彼此内心想法">
              <div className="txt">
                <em className="b">男方：</em>
                {state.result.bbcnxxf}
              </div>
              <div className="txt">
                <em className="g">女方：</em>
                {state.result.gbcnxxf}
              </div>
            </ContentCard>
            <ContentCard title="未来关系发展">
              <div className="txt">{state.result.wlgxfz}</div>
            </ContentCard>
            <ContentCard title="恋人相处指南">
              <div className="txt">{state.result.lrxczn}</div>
            </ContentCard>
          </Card>
          <Card title="你的专属恋爱宝典">
            <ContentCard title="关系升温">
              <div className="txt">
                你们的配对指数很高，彼此缘分很深厚，定可成为良缘佳话，可用以下方式来进一步提升彼此关系：
              </div>
              <h2>【能见面，就多见面】</h2>
              <div className="txt">{state.result.njmjdjm}</div>

              <h2>【尊重两性差异】</h2>
              <div className="txt">{state.result.zzlxcy}</div>

              <h2>【互相付出】</h2>
              <div className="txt">{state.result.hxfc}</div>
            </ContentCard>
            <ContentCard title="感情增进">
              <div className="txt">
                你们的配对指数很高，关系会很美满和谐，想要让爱情更加甜蜜，可以用以下方式来进一步提升：
              </div>
              <h2>【主动自我表露】</h2>
              <div className="txt">{state.result.zdzwbl}</div>
              <h2>【多表达感激】</h2>
              <div className="txt">{state.result.dbdgj}</div>
            </ContentCard>
            <ContentCard title="冲突化解">
              <div className="txt">
                你们的配对指数很高，感情会很顺遂，如遇有冲突发生，可以采取以下方式来化解：
              </div>
              <h2>【神秘感】</h2>
              <div className="txt">{state.result.smg}</div>
              <h2>【塑造自身价值】</h2>
              <div className="txt">{state.result.szzsjz}</div>
            </ContentCard>
          </Card>

          <div className="ads">
            <ClassifyHeader title="热门推荐" />
            <a className="ad qinggan" href="https://qinggan.ijianshui.com?channel=b6sa$69">
              <img src={AdQinggan} />
            </a>

            <a className="ad yinyuan" href="https://yanyuan.ijianshui.com/?channel=uyvs$53">
              <img src={AdYinyuan} />
            </a>

            <a className="ad name" href="https://l1.ijianshui.com/?channel=jnwt$64">
              <img src={AdName} />
            </a>
          </div>
        </div>

        <Button className="submit" onClick={onBackHome}>
          再测试一次
        </Button>

        <FeedBack orderNo={orderId} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm }}
      />
    </Layout>
  )
}

export default PageResult
