import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import './style.scss'

const xings = [
  '王',
  '李',
  '张',
  '刘',
  '陈',
  '杨',
  '黄',
  '赵',
  '吴',
  '周',
  '徐',
  '孙',
  '马',
  '朱',
  '胡',
  '郭',
  '何',
  '林',
  '罗',
  '高',
  '郑',
  '梁',
  '谢',
  '宋',
  '唐',
  '许',
  '韩',
  '邓',
  '冯',
  '曹',
  '彭',
  '曾',
  '肖',
  '田',
  '董',
  '潘',
  '袁',
  '蔡',
  '蒋',
  '余',
  '于',
  '杜',
  '叶',
  '程',
  '魏',
  '苏',
  '吕',
  '丁',
  '任',
  '卢',
  '姚',
  '沈',
  '钟',
  '姜',
  '崔',
  '潭',
  '陆',
  '范',
  '汪',
  '廖',
  '石',
  '金',
  '韦',
  '贾',
  '夏',
  '付',
  '方',
  '邹',
  '熊',
  '白',
  '孟',
  '秦',
  '邱',
  '便',
  '江',
  '尹',
  '薛',
  '闫',
  '段',
  '雷',
  '龙',
  '黎',
  '史',
  '陶',
  '贺',
  '毛',
  '郝',
  '顾',
  '龚',
  '邵',
  '万',
  '罩',
  '武',
  '钱',
  '戴',
  '严',
  '莫',
  '孔',
  '向',
  '常',
]

const cw = ['先生', '女士']

// const time = [
//   '1秒钟之前',
//   '2秒钟之前',
//   '3秒钟之前',
//   '4秒钟之前',
//   '5秒钟之前',
//   '6秒钟之前',
//   '7秒钟之前',
//   '8秒钟之前',
//   '9秒钟之前',
//   '10秒钟之前',
//   '11秒钟之前',
//   '12秒钟之前',
//   '13秒钟之前',
//   '14秒钟之前',
//   '15秒钟之前',
// ]

export default function FakeBubble() {
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')

  const randomText = () => {
    const xingtext = xings[Math.floor(Math.random() * xings.length)]
    // const timetext = time[Math.floor(Math.random() * time.length)]
    const cwtext = cw[Math.floor(Math.random() * cw.length)]

    return `刚刚 ${xingtext}${cwtext} 成功领取合婚测试报告`
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setShow(true)
      setText(randomText())
      setTimeout(() => {
        setShow(false)
      }, 6000)
    }, 7000)

    return () => {
      timer && clearInterval(timer)
    }
  }, [])

  return (
    <div className="com-fake-bubble">
      <div
        className={cn('content', {
          show,
        })}
      >
        {text}
      </div>
    </div>
  )
}
