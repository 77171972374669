import { Button, Cell, Input, Layout, Loading, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import http from '../../utils/http'
import './style.scss'

interface IState {
  loading: boolean
  errorMsg: string
  orderNo: string
  list: any
}

const PageIndex = () => {
  const navigateHook = useNavigate()

  const [state, setState] = useSetState<IState>({
    loading: false,
    errorMsg: '',
    orderNo: '',
    list: [],
  })

  const onInputChange = (e: any) => {
    const value = e.target.value

    if (!value || !value.trim()) {
      return
    }

    setState({
      orderNo: value,
    })
  }

  const fetchOrderByOrderNo = async () => {
    try {
      if (!state.orderNo) {
        Toast('请输入订单号')
        return
      }

      Loading()
      const result: any = await http.request({
        url: '/order/orderList',
        method: 'POST',
        data: {
          orderId: state.orderNo,
        },
      })
      const { body = [] } = result

      if (!body || !Array.isArray(body) || body.length === 0) {
        Toast('没有查找到相关订单')
        return
      }

      setState({
        list: body,
      })
    } catch (error: any) {
      Toast(error.message || '')
    } finally {
      Loading.hide()
      setState({
        orderNo: '',
      })
    }
  }

  const fetchOrderList = async () => {
    try {
      setState({ loading: true })
      const result: any = await http.request({
        url: '/order/orderList',
        method: 'POST',
        data: {},
      })

      const { body = [] } = result

      setState({
        list: body || [],
      })
    } catch (error: any) {
      setState({
        errorMsg: error.message || '',
      })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onEnterOrderDetail = (orderNo: string) => {
    navigateHook(`/result?orderNo=${orderNo}&backTo=order`)
  }

  // const onBackHome = () => {
  //   navigateHook('/', { replace: true })
  // }

  useEffect(() => {
    fetchOrderList()
  }, [])

  return (
    <Layout className="page-order" useBottomSafeArea={false}>
      {/* <Layout.Header
        title="我的订单"
        className="header"
        addonBefore={<div className="back-home" onClick={onBackHome} />}
      /> */}
      <Layout.Body errorInfo={state.errorMsg} loading={state.loading}>
        <div className="search">
          <Input onChange={onInputChange} placeholder="请输入订单号" allowClear />
          <div className="btn">
            <Button mini type="danger" onClick={fetchOrderByOrderNo}>
              搜索
            </Button>
          </div>
        </div>

        {state.list.length > 0 ? (
          <Cell onClick={onEnterOrderDetail} className="list" arrow={true} dividerIndent="left">
            {state.list.map((item: any) => {
              const { bName, gName } = item.orderParams
              return (
                <Cell.Row
                  key={item.orderId}
                  className="row"
                  value={item.orderId}
                  addonAfter={
                    item.status === 2 ? (
                      <span className="blue">支付成功</span>
                    ) : (
                      <span className="red">未支付</span>
                    )
                  }
                >
                  <div className="info">
                    <div className="title">{bName + '与' + gName + '合婚'}</div>
                    <div className="orderNo">订单号：{item.orderId}</div>
                    <div className="time">配对时间：{item.orderTime}</div>
                  </div>
                </Cell.Row>
              )
            })}
          </Cell>
        ) : (
          <div className="nodata">
            暂无订单数据
            <br />
            您可以直接输入订单号搜索
          </div>
        )}
      </Layout.Body>
    </Layout>
  )
}

export default PageIndex
