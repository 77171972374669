import cn from 'classnames'
import React from 'react'
import './style.scss'

interface IProps {
  title: string
  contents?: string[]
  lock?: boolean
  lockTips?: string[]
  children?: React.ReactNode
  onBtnClick?: () => void
}

export default function ContentCard(props: IProps) {
  const { title, contents = [], lock = false, lockTips = [], onBtnClick } = props

  return (
    <div className="com-content-card">
      <div className="com-content-card-title">{title}</div>
      <div
        className={cn('com-content-card-content', {
          lock,
        })}
      >
        {contents.map((item, index) => (
          <div key={index} className="p">
            {item}
          </div>
        ))}

        {props.children}
      </div>

      {lock && (
        <div className="com-content-card-btn" onClick={onBtnClick}>
          立即查看
        </div>
      )}
      {lock && lockTips.length > 0 && (
        <div className="com-content-card-lock-tips">
          {lockTips.map((item, index) => {
            return <div className="p" dangerouslySetInnerHTML={{ __html: item }} key={index} />
          })}
        </div>
      )}
    </div>
  )
}
