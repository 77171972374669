import React from 'react'
import './style.scss'

const dataTags = [
  '很专业(6.9万)',
  '测试很准(5.9万)',
  '好评(3.5万)',
  '婚恋必备(2.3万)',
  '有理有据(1.1万)',
  '分析很好(1万)',
]

const dataComment = [
  {
    avatar: './imgs/01.png',
    name: '张*非',
    time: '5天前',
    desc: '抱着花钱不多试试看的心情来的，结果确实出乎意料，真心推荐给大家',
    liulan: '浏览109969次',
    zan: '89909',
  },
  {
    avatar: './imgs/02.png',
    name: '李*云',
    time: '3天前',
    desc: '真的很不错，简短几句话道破一切，比其他的那些胡说八道的轻多了，强烈推荐',
    liulan: '浏览108069次',
    zan: '67892',
  },
  {
    avatar: './imgs/03.png',
    name: '杨*达',
    time: '6小时前',
    desc: '结合了很多因素分析的很全面，非常满意',
    liulan: '浏览63633次',
    zan: '37690',
  },
  {
    avatar: './imgs/04.png',
    name: '张*',
    time: '9小时前',
    desc: '解释的通俗易懂，一看就是非常专业的那种，非常感谢和佩服',
    liulan: '浏览43969次',
    zan: '26843',
  },
  {
    avatar: './imgs/05.png',
    name: '*起',
    time: '12小时前',
    desc: '分析的很全面，值得推荐',
    liulan: '浏览23960次',
    zan: '15899',
  },
  {
    avatar: './imgs/06.png',
    name: '李*元',
    time: '1小时前',
    desc: '分析的很全面，而且还给了很多建议，值得信任和推荐',
    liulan: '浏览21259次',
    zan: '14812',
  },
  {
    avatar: './imgs/07.png',
    name: '王*超',
    time: '30分钟前',
    desc: '分析的很准很全面，谢谢建议',
    liulan: '浏览29969次',
    zan: '9833',
  },
  {
    avatar: './imgs/08.png',
    name: '刘*超',
    time: '10小时前',
    desc: '分析的很仔细，很专业，注意事项也会提醒，感谢',
    liulan: '浏览17964次',
    zan: '6890',
  },
  {
    avatar: './imgs/09.png',
    name: '张*涛',
    time: '2天前',
    desc: '分析的很准，给个好评。',
    liulan: '浏览14955次',
    zan: '5899',
  },
  {
    avatar: './imgs/10.png',
    name: '张*远',
    time: '2小时前',
    desc: '非常专业，思路清晰，受益匪浅',
    liulan: '浏览5900次',
    zan: '2896',
  },
]

export default function FakeComment() {
  return (
    <div className="com-fake-comment">
      <div className="inner">
        {/* <div className="title">好评如潮</div> */}
        <div className="tags">
          {dataTags.map((item: string, index: number) => (
            <div className="tag" key={index}>
              {item}
            </div>
          ))}
        </div>
        <div className="scroller">
          <div className="list w1">
            {dataComment.map((item: any, index: number) => {
              return (
                <div className="comment" key={index}>
                  <div className="head">
                    <div className="avatar">
                      <img src={require(`${item.avatar}`)} />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="time">{item.time}</div>
                  </div>

                  <div className="desc">{item.desc}</div>

                  <div className="data">
                    <div className="liulan">{item.liulan}</div>
                    <div className="zan">{item.zan}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="list w2">
            {dataComment.map((item: any, index: number) => {
              return (
                <div className="comment" key={index}>
                  <div className="head">
                    <div className="avatar">
                      <img src={require(`${item.avatar}`)} />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="time">{item.time}</div>
                  </div>

                  <div className="desc">{item.desc}</div>

                  <div className="data">
                    <div className="liulan">{item.liulan}</div>
                    <div className="zan">{item.zan}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
