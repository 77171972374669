import { Search } from 'auto-libs'
import { Button, Dialog, Layout, Radio, Toast } from 'auto-ui'
import cn from 'classnames'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import Card from '../../components/card'
import FeedBack from '../../components/feedback'
import Footer from '../../components/footer'
import { domain } from '../../utils/config'
import http from '../../utils/http'
import { sleep } from '../../utils/time'
import CouponPop from './components/coupon-pop/index'
import './style.scss'

import {
  getHomePageCouponPop,
  getUserId,
  setHomePageCouponPop,
  setUserId,
  delXNameTest,
  delInfoInputAnimationEnd,
} from '../../utils/storage'

interface IState {
  male: string
  female: string
  checkedAgreement: boolean
  popVisible: boolean
  couponPopVisible: boolean
  orderNo: string
  isShowFixedSubmit: boolean
}

const PageIndex = () => {
  const navigateHook = useNavigate()
  const cb = Search.get('callback') || ''
  const channel = Search.get('channel') || ''
  const orderNo = Search.get('orderNo') || ''

  const [state, setState] = useSetState<IState>({
    male: '',
    female: '',
    checkedAgreement: false,
    popVisible: false,
    couponPopVisible: false,
    orderNo,
    isShowFixedSubmit: false,
  })

  const onInputChange = (e: any) => {
    const value = e.target.value
    const id = e.target.id

    setState({
      [id]: value,
    })
  }

  const onAgreementClick = () => {
    setState({
      checkedAgreement: !state.checkedAgreement,
    })
  }

  const isChinese = (name: string) => {
    const reg = /^[\u4E00-\u9FA5]{2,4}$/
    return reg.test(name)
  }

  const onSubmit = async () => {
    try {
      const { male, female } = state

      if (!isChinese(male) || !isChinese(female)) {
        Toast('请填写2-4个字中文汉字')
        return
      }

      delInfoInputAnimationEnd()

      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          bName: male,
          gName: female,
          adType: channel ? channel : 'kuaishou',
          adParams: cb,
          channel: channel,
          productType: '10',
        },
      })

      const { code, body } = result
      if (code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      const { orderId } = body

      setState({
        orderNo: orderId,
      })

      navigateHook(`/?orderNo=${orderId}`, { replace: true })

      await sleep(100)

      delXNameTest()
      setHomePageCouponPop('true')
      navigateHook(`/pay?male=${male}&female=${female}&orderNo=${orderId}`)
    } catch (e) {
      Toast('请求失败，请再试')
    }
  }

  const fetchUserId = async () => {
    try {
      const result: any = await http.request({
        url: '/user/getNewUserId',
        method: 'POST',
        data: {},
      })

      const { code, body } = result

      if (code !== '00000000') {
        return
      }

      const { userId } = body

      setUserId(userId)
      statistics(userId)
    } catch (error) {}
  }

  const statistics = async (userId: string) => {
    const params: any = { userId }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  const onOrderClick = () => {
    window.location.href = domain + '/order'
  }

  const onCouponPopClose = () => {
    setState({
      couponPopVisible: false,
    })
  }

  const onScroll = (e: any) => {
    if (e.target.scrollTop >= 700) {
      setState({
        isShowFixedSubmit: true,
      })
    } else {
      setState({
        isShowFixedSubmit: false,
      })
    }
  }

  useEffect(() => {
    if (getHomePageCouponPop() === 'true') {
      setState({
        couponPopVisible: true,
      })
    }

    const userId = getUserId()
    if (userId) {
      statistics(userId)
      return
    }

    fetchUserId()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body onScroll={onScroll}>
        <div className="slogan" />
        <div className="bjx-wrapper">
          <div className="bjx" />
          <div className="photo" />
        </div>

        <div className="form">
          <div className="input-wrapper">
            <div className="item male">
              <input
                id="male"
                placeholder="请输入男生姓名"
                value={state.male}
                onChange={onInputChange}
                maxLength={4}
              />
            </div>
            <div className="heart" />
            <div className="item female">
              <input
                id="female"
                placeholder="请输入女生姓名"
                value={state.female}
                onChange={onInputChange}
                maxLength={4}
              />
            </div>
          </div>

          <div className="btns">
            <Button className="submit" onClick={onSubmit}>
              立即配对
            </Button>
            <div className="agreement" onClick={onAgreementClick}>
              <Radio checked={state.checkedAgreement} className="checkbox" />
              <div className="text">
                您的信息仅用于生成分析报告，
                <a
                  onClick={(e) => {
                    setState({
                      popVisible: true,
                    })
                    e.stopPropagation()
                  }}
                >
                  查看隐私协议
                </a>
              </div>
            </div>

            <div className="tip">
              已有<em>789006</em>人解领取报告，好评率高达<em>99.76%</em>
            </div>

            <div className="order" onClick={onOrderClick}>
              <a>查看我的订单&gt;</a>
            </div>
          </div>

          <div className="info">
            <Card title="解决你的恋爱烦恼">
              <div className="item">
                <img src={require('./imgs/w1.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/w2.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/w3.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/w4.png')} />
              </div>
            </Card>

            <Card title="揭晓你们的配对指数" className="card-jxpdzs">
              <div className="item tm">
                <div className="desc">
                  配对相合
                  <br />
                  则恋爱甜蜜
                </div>
                <div className="img" />
              </div>
              <div className="item md">
                <div className="desc ">
                  配对不和
                  <br />
                  则矛盾不断
                </div>
                <div className="img" />
              </div>
              <div className="item sx">
                姓名配对，通过男女二人的<em>姓名合盘</em>
                ，将二人有利、不利的因素进行结合分析，对二人的<em>配对指数、关系质量、关系发展</em>
                做出专业评定，并给予合理可行的<em>感情建议，成就美好恋情！</em>
              </div>
              <div className="item">
                <img src={require('./imgs/pdjds.png')} />
              </div>
            </Card>

            <Card title="指引你的恋爱发展">
              <div className="item">
                <div className="imgs">
                  <img src={require('./imgs/zy1.png')} />
                  <img src={require('./imgs/zy2.png')} />
                </div>
              </div>
              <div className="item">
                <img src={require('./imgs/zy3.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/zy4.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/zy5.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/zy6.png')} />
              </div>
              <div className="item">
                <img src={require('./imgs/zy.png')} />
              </div>
            </Card>
          </div>
        </div>

        <FeedBack orderNo={state.orderNo} />

        <Footer />

        {/* <FakeComment /> */}
        {/* <div className="tip-warning">需付费后方可查看结果，结果纯属娱乐仅供参考</div> */}

        {state.isShowFixedSubmit && (
          <div className={cn('fixed-btn')}>
            <Button className="fixed-submit" onClick={onSubmit}>
              立即配对
            </Button>
          </div>
        )}
      </Layout.Body>
      <Dialog
        visible={state.popVisible}
        height="80%"
        title="个人隐私协议"
        onCloseClick={() =>
          setState({
            popVisible: false,
          })
        }
        onMaskClick={() =>
          setState({
            popVisible: false,
          })
        }
      >
        <Dialog.Scroller>
          <div className="content">
            <div className="text">
              <p>尊敬的用户，欢迎阅读本协议：</p>
              <p>
                上海简水网络科技有限公司依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的上海简水网络科技有限公司服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
              </p>
              <p>
                用户在申请上海简水网络科技有限公司服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。
                <strong>
                  若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。
                </strong>
              </p>
              <p>
                保护用户个人信息是上海简水网络科技有限公司的一项基本原则，上海简水网络科技有限公司运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。
                <strong>
                  未经用户许可上海简水网络科技有限公司不会向第三方（上海简水网络科技有限公司控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。
                </strong>
              </p>
              <p>
                在用户发送信息的过程中和本网站收到信息后，
                <strong>
                  本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。
                </strong>
              </p>
              <p>
                此外，您已知悉并同意：
                <strong>
                  在现行法律法规允许的范围内，上海简水网络科技有限公司可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用上海简水网络科技有限公司服务或产品的情况所认为您可能会感兴趣的信息。
                </strong>
              </p>
              <p>
                本网站有权在必要时修改服务条例，
                <strong>
                  本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。
                </strong>
                根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。
              </p>
              <p>上海简水网络科技有限公司保留对本协议的最终解释权。</p>
            </div>
          </div>
        </Dialog.Scroller>
      </Dialog>

      <CouponPop
        visible={state.couponPopVisible}
        onPopClose={onCouponPopClose}
        orderNo={state.orderNo}
      />
    </Layout>
  )
}

export default PageIndex
