export const unlockdata = [
  {
    title: '你们的恋爱性格',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '分析Ta的<em>恋爱性格</em>和<em>内心想法</em>？',
      'Ta对你<em>有感觉/是真心</em>吗？',
      '追求Ta<em>会被拒绝</em>吗？',
    ],
  },
  {
    title: '你们的配对指数',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '分析你们的<em>配对指数</em>',
      'Ta是你的<em>命中真爱</em>吗？',
      '你们<em>在一起会幸福</em>吗？',
    ],
  },
  {
    title: '你们的关系发展',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '分析你们的<em>未来关系发展</em>',
      'Ta会<em>一直对你好吗</em>？',
      '未来会有哪些<em>变数</em>？',
    ],
  },
  {
    title: '你们的爱情危机',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '两人相处时会出现哪些<em>危机</em>？',
      '引发危机的<em>原因</em>都有什么？',
      '如何<em>破解危机</em>，<em>守护爱情</em>？',
    ],
  },
  {
    title: '婚前婚后变化',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '谈恋爱时<em>对方</em>是怎样的人？',
      '怎样让对方有<em>被爱的幸福感</em>？',
      '结婚之后会变成<em>哪种丈夫/妻子</em>？',
      '结婚之后你们会有<em>哪些变化</em>？',
    ],
  },
  {
    title: '你的专属恋爱宝典',
    content: [
      '职场上总是存在这些「不公平」:有些人毕业 2 年月薪 3w，有人工作 10 年却月薪 5K；加班的是你，升职的却是他人;明明很努力，但工作还是没有突破；换过很多工作，依旧不确定自己擅长什么……不要怀疑人生，只是你还不够了解自己的性格特点，没匹配到合适的工作，没能发挥真正实力',
    ],
    lock: true,
    lockTips: [
      '专业老师为你<em>量身定制</em>的<em>恋爱宝典</em>',
      '专属的关系<em>升温/感情增进方法</em>',
      '助你成就<em>幸福满满的爱情</em>',
    ],
  },
]
