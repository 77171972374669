import { Search } from 'auto-libs'
import { Button, Layout, Loading, Toast } from 'auto-ui'
import cn from 'classnames'
import { useEffect } from 'react'
import CountUp from 'react-countup'
import { useSetState } from 'react-use'
import Card from '../../components/card'
import ContentCard from '../../components/content-card'
import Footer from '../../components/footer'
import { aliPay, wxPay } from '../../utils/pay'
import { getCountDown, sleep } from '../../utils/time'
import FakeBubble from './components/fake-buble'
import FakeComment from './components/fake-comment'
import LetterSpell from './components/letter-spell'
import Steps from './components/steps'
import { unlockdata } from './data'
import Banner from './imgs/banner@2x.png'
import './style.scss'

interface IState {
  isAnimationEnd: boolean
  time: number
  payType: 'wechat' | 'alipay'
  alipayForm: any
  isCountUpEnd: boolean
  isShowFixedSubmit: boolean
}

const PagePay = () => {
  const [state, setState] = useSetState<IState>({
    isAnimationEnd: false,
    time: 60 * 5 - 1,
    payType: 'wechat',
    alipayForm: null,
    isCountUpEnd: false,
    isShowFixedSubmit: false,
  })
  const { orderNo, male, female } = Search.parse()

  let timer: any = null

  const countdown = (time: number) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }

      setState({
        time: _time,
      })

      countdown(_time)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()
      const result = await wxPay(orderNo, discount)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async (discount?: string) => {
    try {
      Loading()
      const result = await aliPay(orderNo, discount)

      setState({
        alipayForm: result,
      })

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onSubmit = () => {
    if (state.payType === 'alipay') {
      onAlipay()
    } else {
      onWechatPay()
    }
  }

  const onStepAnimationEnd = async () => {
    setState({
      isAnimationEnd: true,
    })

    await sleep(100)

    document.getElementsByClassName('x-app-body')[0].scrollTo({ top: 560, behavior: 'smooth' })
  }

  const onScroll = (e: any) => {
    if (e.target.scrollTop >= 1300 || e.target.scrollTop <= 600) {
      setState({
        isShowFixedSubmit: true,
      })
    } else {
      setState({
        isShowFixedSubmit: false,
      })
    }
  }

  useEffect(() => {
    countdown(60 * 5 - 1)

    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay" useBottomSafeArea={false}>
      <Layout.Body className="body" onScroll={onScroll}>
        <div className="banner">
          <img src={Banner} />
        </div>

        <div className="names">
          <LetterSpell letters={male} type="male" style={{ color: 'rgba(43, 55, 198, 1)' }} />
          <LetterSpell letters={female} type="female" style={{ color: 'rgb(208, 28, 28)' }} />

          <div className="scores">
            <div className="circle circle1">过去</div>
            <div className="circle circle2">现在</div>
            <div className="circle circle3">未来</div>
            <div className="star">
              <div className="score">
                {state.isCountUpEnd ? (
                  <span>??</span>
                ) : (
                  <CountUp
                    delay={1}
                    start={60}
                    end={99}
                    duration={6}
                    onEnd={() => setState({ isCountUpEnd: true })}
                  />
                )}
              </div>
              <div className="txt">配对指数</div>
            </div>
            <div className="tip">平生愿得一人心 相爱白头不相离</div>
          </div>
        </div>

        {!state.isAnimationEnd ? (
          <Steps onStepAnimationEnd={onStepAnimationEnd} />
        ) : (
          <>
            <Card title="你们的配对报告已生成" className="pay-panel">
              <div className="locks">
                <div className="lock lock1">
                  <div className="txt">发展关系</div>
                </div>
                <div className="lock  lock2 purple big">
                  <div className="txt">配对指数</div>
                </div>
                <div className="lock  lock3">
                  <div className="txt">是否真爱</div>
                </div>
                <div className="lock small  lock4">
                  <div className="txt">恋爱性格</div>
                </div>
                <div className="lock small  lock5">
                  <div className="txt">感情质量</div>
                </div>
                <div className="lock small  lock6">
                  <div className="txt">未来变数</div>
                </div>
                <div className="lock small  lock7">
                  <div className="txt">恋爱宝典</div>
                </div>
              </div>

              <div className="desc">
                <em>资深配对老师</em>根据你们的姓名量身定制的<em>专属配对报告，一人仅此一份</em>
                ，内容媲美 美千元亲测，<em>涵盖感情方方面面</em>。
                <div>
                  已有<em>896520</em>人购买，好评率高达<em>96.86%</em>
                </div>
              </div>

              <div className="price">
                <div className="discount">
                  <span className="original">原价138元</span> <span>限时优惠倒计时</span>
                </div>
                <div className="current">
                  <span>结缘价</span>
                  <em>￥29.9</em>
                  <div className="countdown">{getCountDown(state.time)}</div>
                </div>
              </div>

              <div className="payways">
                <div
                  className={cn('payway wechat', {
                    checked: state.payType === 'wechat',
                  })}
                  onClick={() => setState({ payType: 'wechat' })}
                >
                  <div className="icon" />
                  <div className="name">微信</div>
                  <div className="check" />
                </div>
                {/* <div
                  className={cn('payway alipay', {
                    checked: state.payType === 'alipay',
                  })}
                  onClick={() => setState({ payType: 'alipay' })}
                >
                  <div className="icon" />
                  <div className="name">支付宝</div>
                  <div className="check" />
                </div> */}
              </div>
              <Button className="submit" onClick={onSubmit}>
                立即解锁配对报告
              </Button>
            </Card>

            <Card title="你们的配对报告已生成">
              {unlockdata.map((item, index) => (
                <ContentCard
                  key={index}
                  lock={item.lock}
                  lockTips={item.lockTips}
                  title={item.title}
                  contents={item.content}
                  onBtnClick={onSubmit}
                />
              ))}
            </Card>

            <Card className="comment" title="好评如潮">
              <FakeComment />
            </Card>

            <FakeBubble />

            {state.isShowFixedSubmit && (
              <div className={cn('fixed-btn')}>
                <Button className="fixed-submit" onClick={onSubmit}>
                  立即解锁配对报告
                </Button>
              </div>
            )}
          </>
        )}

        <Footer />
      </Layout.Body>

      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: state.alipayForm as string }}
      />
    </Layout>
  )
}

export default PagePay
